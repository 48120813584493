/** @type {import('./types/harmony-config').IHarmonyConfig} */
const CONFIG = {
    title: 'theDEAL',
    description: 'theDEAL 마켓플레이스',
    domain: 'the-deal',
    testEmail: 'super@freedgrouptech.com',
    testPassword: 'asdf123',
    locales: ['ko'],
    defaultLocale: 'ko',
    defaultCurrency: 'KRW',
    backgroundColor: '#FFFFFF',
    primaryTextColor: '#000000',
    primaryFont: 'noto',
    secondaryFont: 'poppins',
    marketplaceTemplate: ['tangible', 'intangible'],
    loginType: ['login'],
    isPwa: true,

    // Keep at bottom of file for pipeline build
    designTemplate: 'designC',
};

module.exports = CONFIG;
